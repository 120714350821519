import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { getScanRecord, changeQuery, changeScanSearch, scanRecordExport } = iceStarkData.store.get('commonAction')?.queryTools;
import { formatDate } from 'ucode-utils';
import { handSortOrder } from 'ucode-utils';
import { SearchBox, SearchInput, DataTable, Pager, Button, notification } from 'tyb';
import { NewSelect } from 'ucode-components';
import enums from '@/assets/enum.json';
import { DownLoadAnimate } from 'ucode-components'
@connect(
    (state) => {
        return {
            scanRecord: state.queryTools.scanRecord,
            scanPaging: state.queryTools.scanPaging,
            perPageCountList: state.queryTools.perPageCountList,
            scanTotalElements: state.queryTools.scanTotalElements,
            tagSearch: state.queryTools.tagSearch,
            sloading: state.queryTools.sloading,
            codeQuerySearch: state.queryTools.codeQuerySearch,
            savePrevquery: state.queryTools.savePrevquery,
        }
    },
    {
        changeScanSearch,
        changeQuery,
        setNavBar,
        getScanRecord: getScanRecord.REQUEST,
        scanRecordExport: scanRecordExport.REQUEST,
    }
)

class ScanInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchType: [
                {
                    text: 'IP',
                    value: 'ip'
                },
                {
                    text: 'openId',
                    value: 'openId'
                },
            ],
            tableColumns: [ /* 表格 */
                {
                    key: 'openId',
                    dataIndex: 'openId',
                    title: 'openId',
                },
                {
                    key: 'appId',
                    dataIndex: 'appId',
                    title: 'appId',
                },
                {
                    key: 'ip',
                    dataIndex: 'location',
                    title: '扫码IP',
                    render: (value, record) => <div>{value && value.ip}</div>
                },
                {
                    key: 'location',
                    dataIndex: 'location',
                    title: '扫码GPS',
                    render: (value, record) => <div>
                        <div>经度：{value && value.longitude}</div>
                        <div>维度：{value && value.latitude}</div>
                    </div>
                },

                {
                    key: 'ti',
                    dataIndex: 'time',
                    title: '扫码时间',
                    render: (value, record) => <div>{formatDate(value)}</div>
                },
                {
                    key: 'promotionType',
                    dataIndex: 'promotionType',
                    title: '扫码类型',
                    render: (value, record) => <div>{value == 'OPEN_BOX' ? 'B端扫码' : value == 'QR_CODE' ? 'C端扫码' : value == 'ANTI_COUNTERFEITING' ? '防伪扫码' : '其他'}</div>
                },
                {
                    key: 'promotionId',
                    dataIndex: 'promotionId',
                    title: '活动Id',
                },
                {
                    key: 'adInfo',
                    dataIndex: 'adInfo',
                    title: '扫码IP地点', // adInfo 行政区划信息 provinceName cityName
                    render: (value, record) => <div>{value && value.adName}</div>
                },


            ],
            tiSort: null,
            animateShow: 0,
        };
    }

    componentDidMount() {
        const { changeQuery, getScanRecord, tagSearch: { codeUri, senNum }, scanPaging, savePrevquery: { scanQuery }, codeQuerySearch: { query } } = this.props;
        // if (codeUri && senNum && query != scanQuery) {
        //     getScanRecord({
        //         ...scanPaging, callback: (res) => {
        //             if (/^2\d{2}$/.test(res.status)) {
        //                 changeQuery({scanQuery: query});
        //             }
        //         }
        //     });
        // }

        if (codeUri && senNum) {
            getScanRecord({ ...scanPaging });
        }
    }

    renderSortHead = (col) => {
        return (
            <DataTable.SortableColumnHeader
                order={this.state[`${col.key}Sort`]}
                sortKey={col.key}
                onSort={() => { this.handleSort(col.key, this.state[`${col.key}Sort`]) }}
            >{col.title}</DataTable.SortableColumnHeader>
        )
    }

    handleSort = (key, order) => {
        const newOrder = handSortOrder(order);
        const bridgeObg = { tiSort: newOrder };
        const { scanPaging, getScanRecord, tagSearch: { codeUri, senNum } } = this.props;
        scanPaging.sort = `qrs.${key},${newOrder}`;
        if (codeUri && senNum) {
            getScanRecord({ ...scanPaging });
        }
        this.setState(bridgeObg);
    }

    getColumns() {
        return this.state.tableColumns.map(column => {
            if (['time'].includes(column.dataIndex)) {
                return {
                    ...column,
                    headerRender: this.renderSortHead
                }

            } else {
                return column
            }
        })
    }
    //导出
    templateExport = () => {
        const { scanPaging } = this.props;

        this.props.scanRecordExport({
            ...scanPaging,
            callback: () => {
                this.setState({ animateShow: this.state.animateShow + 1 })

            }
        });
        notification.success('导出成功');

    }
    render() {
        const { searchType } = this.state;
        const { sloading, tagSearch, scanRecord, scanPaging, perPageCountList, scanTotalElements, getScanRecord, changeScanSearch } = this.props;
        const listStyle = scanRecord.length > 0 && !sloading;
        const { codeUri, senNum } = tagSearch;
        const isScan = codeUri && senNum && scanPaging.isSearch && scanRecord.length == 0 ? '暂无数据' : '二维码还未扫码';
        return (
            <div className={`table-tr-middle`} style={{ paddingBottom: !listStyle && 20 }}>
                <div className="searchbar-box" style={{ overflow: 'visible' }}>
                    {scanRecord.length > 0 && <Button className="tc-15-btn export" onClick={(e) => this.templateExport(true)} style={{ marginRight: 10 }}>导出</Button>}
                    <SearchInput
                        // className="search-input"
                        className="search-input-block"
                        placeholder="输入IP或openID"
                        onChange={(value) => { changeScanSearch({ keyWords: value }) }}
                        // onClear={() => { changeScanSearch({ keyWords: '' }) }}
                        onSearch={() => {
                            if (!scanPaging.type) {
                                notification.error('请选择查询方式');
                            } else { // ip openId
                                const { codeUri, senNum } = tagSearch;
                                if (codeUri && senNum) {
                                    scanPaging.isSearch = false;
                                    if (scanPaging.keyWords) {
                                        scanPaging.isSearch = true;
                                    }
                                    getScanRecord({ ...scanPaging, ip: '', openId: '', [`${scanPaging.type}`]: scanPaging.keyWords })
                                }
                            }
                        }}
                        value={scanPaging.keyWords}
                    />
                    <NewSelect
                        className="table-search-select"
                        style={{ float: 'right', marginRight: 8 }}
                        value={scanPaging.type}
                        list={searchType}
                        placeholder="方式"
                        onChange={(value) => {
                            changeScanSearch({ type: value })
                        }}
                    />
                    <div style={{ clear: 'both' }}></div>
                </div>
                <DataTable
                    columns={this.getColumns()}
                    data={scanRecord}
                    rowKey="id"
                    messageRender={({ data, error }) => {
                        if (error != null) { return error.msg; }
                        if (data == null || data.length === 0) { return <span className="text-weak">{isScan}</span>; }
                    }}
                    loading={sloading}
                />
                {listStyle && <div className="page-padding20">
                    <Pager
                        total={scanTotalElements}
                        current={scanPaging.page}
                        perPageCount={scanPaging.size}
                        perPageCountList={perPageCountList}
                        onPageChange={e => { getScanRecord({ ...scanPaging, page: e }) }}
                        onPerPageChange={e => { getScanRecord({ ...scanPaging, size: e, page: 0 }) }} // 每页大小
                    />
                </div>}
                <DownLoadAnimate animateIcon='.searchbar-box .export' animateShow={this.state.animateShow}></DownLoadAnimate>
            </div>
        )
    }
}

export default ScanInfo;