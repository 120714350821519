import React from 'react';
import { formatDate } from 'ucode-utils';
import './UserQuery.scss';
import enums from '@/assets/enum.json';
export const lotteryTableHead = [
  {
    key: 'uuid',
    dataIndex: 'uuid',
    title: '流水号',
    render: (value, record) => <div className="moreline-text-ellipsis" title={value}>{value}</div>
  },
  {
    key: 'poolPrizeId',
    dataIndex: 'poolPrizeId',
    title: '奖品信息',
  },
  {
    key: 'prizeType',
    dataIndex: 'prizeType',
    title: '奖品类型',
    render: (value, record) => <div>{(!record.notWinType || record.notWinType == 'WINNING') ? value && enums.prizeType[value].name : ''}</div>
    // render: value => {
    //   const prizeTypeFilter = [
    //     { text: '微信红包', value: 'RED_PACKET' },
    //     { text: '实物', value: 'PHYSICAL' },
    //     { text: '积分', value: 'POINT' },
    //     { text: '优惠券', value: 'COUPON' },
    //     { text: '虚拟卡', value: 'CARD' },
    //     { text: '零钱转账', value: 'CHANGE_OF_MONEY' },
    //     { text: '核销', value: 'WRITE_OFF' }
    //   ];
    //   return (
    //     <div>

    //       {value ? prizeTypeFilter.filter(v => v.value === value)[0].text : ''}
    //     </div>
    //   );
    // },
  },
  {
    key: 'promotionType',
    dataIndex: 'promotionType',
    title: '活动来源',
    render: value => {
      const promotionTypeFilter = [
        { text: '互动抽奖', value: 'INTERACT' },
        { text: 'C端扫码', value: 'QR_CODE' },
        { text: '邀请抽奖', value: 'INVITE' },
        { text: '签到活动', value: 'CHECK_IN' },
        { text: '传播活动', value: 'DISSEMINATE_PROMOTION' },
        { text: 'B端扫码', value: 'OPEN_BOX' },

        { text: '会员任务', value: 'MEMBER_TASK' },
        { text: '宴会活动', value: 'BANQUET_PROMOTION' },
      ];
      return (
        <div> {value ?promotionTypeFilter.filter (v => v.value === value)[0]? promotionTypeFilter.filter (v => v.value === value)[0].text : '': ''} </div>
      );
    },
  },
  {
    key: 'consumptionName',
    dataIndex: 'consumptionName',
    title: '消耗来源',
    render: (value, record) => {
      // 扫码抽奖显示二维码序号、互动抽奖显示互动事件名称
      // seqNum： 系统序号；eseSeqNum: 企业序号
      const { promotionType = '', consumptionName = '', seqNum = '', eseSeqNum = "" } = record;
      let showMsg = (promotionType == 'INTERACT' || promotionType == 'REGULAR_LOTTERY') ? consumptionName : (promotionType == 'QR_CODE' || promotionType == 'OPEN_BOX' || promotionType == 'BANQUET_PROMOTION' ? `码${eseSeqNum}` : '');
      return <div>{showMsg}</div>
    }
  },
  {
    key: 'tencentBspLevel',
    dataIndex: 'tencentBspLevel',
    title: '安全风险等级',
  },
  {
    key: 'lotteryTime',
    dataIndex: 'lotteryTime',
    title: '中奖时间',
    render: value => <div> {value ? formatDate (value) : ''} </div>,
  },
  {
    key: 'cashTime',
    dataIndex: 'cashTime',
    title: '领奖时间',
    render: value => <div> {value ? formatDate (value) : ''} </div>,
  },
  {
    key: 'prizeStatus',
    dataIndex: 'prizeStatus',
    title: '奖品状态',
    render: (value, record) => {
      let text = record.awardType == 'INTELLIGENT' ? (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || `${record.awardName}(暂未推荐奖品)`) : '未中奖' : record.notWinType == 'WINNING' ? record.poolPrizeName : '未中奖';
      if (text == '未中奖') {
        return <div></div>
      }
      const prizeStatusEnum = {
        'CLAIM_SUCCESS': `${record.prizeType == 'PHYSICAL' ? '未发货' : record.prizeType == 'WRITE_OFF' ? '待核销' : '领取成功'}`,
        'REGISTERED': `${record.prizeType == 'PHYSICAL' ? '已发货' : record.prizeType == 'WRITE_OFF' ? '待核销' : '已核销'}`,
        'NEW_WINNING': '未领取',
        'CLAIM_FAIL': '领取失败',
        'OVERDUE': '已过期',
        'UN_INCENTIVE': '待激励',
        'SCRAP': '已报废',
        
        'UNSHIP': '待发货',
        'SHIP_FAIL': '发放中',
        'GRANT': '发放中',
        'SHIPED': '已发货',
        'REJECT': '驳回',
        'UNCONFIRMED': '待确认',
        'ACCEPTED': '已受理'

      };
      switch (record.prizeType) {
        case 'RED_PACKET':
          if (value === 'CLAIM_SUCCESS' && record.redPacketCashInfo) {
            if (record.redPacketCashInfo.wechatRedPacketLogStatus == 'RECEIVED') {
              prizeStatusEnum.CLAIM_SUCCESS = '已核销';
            } else if (record.redPacketCashInfo.wechatRedPacketLogStatus == 'REFUND') {
              prizeStatusEnum.CLAIM_SUCCESS = '已过期';
            }
          } 
          break;
        case 'PHYSICAL':
          if (value === 'CLAIM_SUCCESS' && record.expressInfo && record.expressInfo.registerStatus == 'REGISTERED') {
            prizeStatusEnum.CLAIM_SUCCESS = '已发货';
          } else {
            prizeStatusEnum.CLAIM_SUCCESS = '未发货';
          }
          break;

        case 'WRITE_OFF':
          if (value === 'CLAIM_SUCCESS' && record.writeOffRecord) {
            if (record.writeOffRecord.writerOffStatus === 'OVER') {
              prizeStatusEnum.CLAIM_SUCCESS = '已核销';
            } else if (record.writeOffRecord.writerOffStatus === 'FAIL') {
              prizeStatusEnum.CLAIM_SUCCESS = '核销失败';
            }

          }

          break
      };
      return <div>{prizeStatusEnum[value]}</div>
    },

  },

  {
    key: 'action',
    dataIndex: 'action',
    title: '发放结果',
  },
];
export const scanTableHead = [
  {
    key: 'eseSeqNum',
    dataIndex: 'eseSeqNum',
    title: '码序号',
  },
  {
    key: 'location.ip',
    dataIndex: 'location.ip',
    title: '扫码IP',
  },
  {
    key: 'location',
    dataIndex: 'location',
    title: '扫码GPS',
    render: (value, record) => <div>
      <div>经度：{value && value.longitude}</div>
      <div>纬度：{value && value.latitude}</div>
    </div>
  },
  {
    key: 'time',
    dataIndex: 'time',
    title: '扫码时间',
    render: value => <div> {value ? formatDate(value) : ''} </div>,
  },
  {
    key: 'promotionId',
    dataIndex: 'promotionId',
    title: '活动ID',
    // render: (value, record) => <div>{value == 'OPEN_BOX' ? 'B端扫码' : value == 'QR_CODE' ? 'C端扫码' : value == 'ANTI_COUNTERFEITING' ? '防伪扫码' : '其他'}</div>
  },
  {
    key: 'adInfo.adName',
    dataIndex: 'adInfo.adName',
    title: '扫码IP地点',
  },
];

export const pointTableHead = [
  {
    key: 'pointName',
    dataIndex: 'pointName',
    title: '积分类型名称',
  },
  {
    key: 'totalConsumeNumber',
    dataIndex: 'totalConsumeNumber',
    title: '总消耗',
  },
  {
    key: 'validNumber',
    dataIndex: 'validNumber',
    title: '总剩余',
  },
  {
    key: 'totalIncomeNumber',
    dataIndex: 'totalIncomeNumber',
    title: '总获取',
  },
  {
    key: 'action',
    dataIndex: 'action',
    title: '明细',
  },
];
export const cardTableHead = [
  {
    key: 'cardName',
    dataIndex: 'cardName',
    title: '卡片类型名称',
  },
  {
    key: 'totalConsumeNumber',
    dataIndex: 'totalConsumeNumber',
    title: '总消耗',
  },
  {
    key: 'validNumber',
    dataIndex: 'validNumber',
    title: '总剩余',
  },
  {
    key: 'totalIncomeNumber',
    dataIndex: 'totalIncomeNumber',
    title: '总获取',
  },
  {
    key: 'action',
    dataIndex: 'action',
    title: '明细',
  },
];
export const usertableHead = [
  {
    key: 'openId',
    dataIndex: 'openId',
    title: 'openID',
  },
  {
    key: 'unionId',
    dataIndex: 'unionId',
    title: 'unionID',
  },
  {
    key: 'memberId',
    dataIndex: 'memberId',
    title: '会员ID',
  },
  {
    key: 'phoneNumber',
    dataIndex: 'phoneNumber',
    title: '手机号',
  },
  {
    key: 'phoneCreatedTime',
    dataIndex: 'phoneCreatedTime',
    title: '手机号创建时间',
    render: value => <div>{value ? formatDate(value) : ''}</div>,
  },
  {
    key: 'phoneUpdatedTime',
    dataIndex: 'phoneUpdatedTime',
    title: '手机号更新时间',
    render: value => <div>{value ? formatDate(value) : ''}</div>,
  },
  {
    key: 'appName',
    dataIndex: 'appName',
    // dataIndex: "appId",
    title: '公众号/小程序',
  },
  {
    key: 'createdTime',
    dataIndex: 'createdTime',
    title: '创建时间',
    render: value => <div>{formatDate (value)}</div>,
  },
  {
    key: 'action',
    dataIndex: 'action',
    title: '基本信息',
  },
];
