import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { getCodeInfoMore, getCodeIdsInfoMore } = iceStarkData.store.get('commonAction')?.queryTools;
const { loadRedpacketQueryInfo } = iceStarkData.store.get('commonAction')?.wechat;
import { handSortOrder } from 'ucode-utils';
import { formatDate } from 'ucode-utils';
import enums from '@/assets/enum.json';
import { SearchBox, SearchInput, DataTable, Pager, Dialog } from 'tyb';
import { RedpacketInfoDialog, ExpressInfoDialog, PrizeDetailDialog } from '../index';

const prizeTypeFilter = [
    { text: '微信红包', value: 'RED_PACKET' },
    { text: '实物', value: 'PHYSICAL' },
    { text: '积分', value: 'POINT' },
    { text: '优惠券', value: 'COUPON' },
    { text: '虚拟卡', value: 'CARD' },
    { text: '零钱转账', value: 'CHANGE_OF_MONEY' }
];
// const promotionTypeFilter = [
//     { text: '互动抽奖', value: 'INTERACT' },
//     { text: '扫码抽奖', value: 'QR_CODE' },
//     { text: '邀请抽奖', value: 'INVITE' },
//     { text: '签到抽奖', value: 'CHECK_IN' },
// ];

@connect(
    (state) => {
        return {
            // drawRecords: state.queryTools.drawRecords,
            // drawPaging: state.queryTools.drawPaging,
            // perPageCountList: state.queryTools.perPageCountList,
            // drawTotalElements: state.queryTools.drawTotalElements,
            // tagSearch: state.queryTools.tagSearch,
            // dloading: state.queryTools.dloading
            ...state.queryTools,
            ...state.wechat,
            codeStatusSelector: state.links.tagstatusSelections || [],
        }
    },
    {
        setNavBar,
        loadRedpacketQueryInfo: loadRedpacketQueryInfo.REQUEST,
        getCodeIdsInfoMore: getCodeIdsInfoMore.REQUEST,
        getCodeInfoMore: getCodeInfoMore.REQUEST,
    }
)

class StateInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datadata: [],
            ids: [],
            idsds: [],
            tableColumns: [ /* 表格 */
                {
                    key: 'updatedTime',
                    dataIndex: 'updatedTime',
                    title: '时间',
                    render: (value, record) => <div>{formatDate(value)}</div>
                },
                {
                    key: 'createByName',
                    dataIndex: 'createByName',
                    title: '操作人',
                },
                {
                    key: 'linkStyle',
                    dataIndex: 'linkStyle',
                    title: '环节类型',
                    render: (value, record) => <div>{enums.linkStyle[value] && enums.linkStyle[value].name}</div>
                },
                {
                    key: 'linkCode',
                    dataIndex: 'linkCode',
                    title: '环节名称', // adInfo 行政区划信息 provinceName cityName
                    render: (value, record) => {
                        let a = ''
                        for (let x in record.dataJsonConvert) {
                            a = x.split('|')[1] ? x.split('|')[1] : value;
                        }

                        return <div style={{ color: '#006eff', cursor: 'pointer' }} onClick={() => { this.getSelect(record) }}>{a}</div>
                    }
                },
                {
                    key: 'adInfo2',
                    dataIndex: 'adInfo2',
                    title: '原状态', // adInfo 行政区划信息 provinceName cityName
                    render: (value, record) => {

                    

                        // let a = {}
                        // for (let i = 0; i < this.state.datadata.length; i++) {
                        //     // this.state.datadata[i].statusNames;
                        //     a = Object.assign(a, this.state.datadata[i].statusNames)
                        // }
                        //  let a =this.state.datadata.map(item => { return {...item.statusNames }})
                        // .join(',');
                        // console.log(a)
                        // console.log(this.state.ids.indexOf(record.id.toString()) - 1 >= 0?this.props.codeStatusSelector.filter(key => key.code ==this.state.idsds[this.state.ids.indexOf(record.id.toString()) - 1].id)[0]:'')
                        return <div>
                            {/* {JSON.stringify(this.state.ids)}
                        {JSON.stringify(this.state.ids.indexOf(record.id.toString()) )} */}
                        
                            {this.state.ids.indexOf(record.id.toString()) - 1 >= 0 ? 
                                this.props.codeStatusSelector.filter(key => key.code ==this.props.codeInfo.linkLogIds[this.state.idsds[this.state.ids.indexOf(record.id.toString()) - 1].id])[0]
                                ?this.props.codeStatusSelector.filter(key => key.code ==this.props.codeInfo.linkLogIds[this.state.idsds[this.state.ids.indexOf(record.id.toString()) - 1].id])[0].name 
                                : '': ''}
                      
                        </div>
                    }

                },
                {
                    key: 'adInfo32',
                    dataIndex: 'adInfo32',
                    title: '变更状态', // adInfo 行政区划信息 provinceName cityName
                    render: (value, record) => {
                        // let a = {}
                        // for (let i = 0; i < this.state.datadata.length; i++) {
                        //     // this.state.datadata[i].statusNames;
                        //     a = Object.assign(a, this.state.datadata[i].statusNames)
                        // }
                        // console.log(a)

                       
                        
                        return <div>{this.props.codeInfo.linkLogIds[record.id] ? this.props.codeStatusSelector.filter(key => key.code == this.props.codeInfo.linkLogIds[record.id])[0]?this.props.codeStatusSelector.filter(key => key.code == this.props.codeInfo.linkLogIds[record.id])[0].name : '' : ''}  </div>
                
                        // return <div>{this.props.codeInfo.linkLogIds[record.id] ? a[this.props.codeInfo.linkLogIds[record.id]] : ''}  </div>
                    }
                },
            ],
            lotiSort: null,
            ctSort: null,
            // 弹窗信息
            modalVisible2: false,
            redTableList: [],
            okList: [],
            failList: [],
            itemArr: [],
            okListItem: [],
            failListItem: [],
        };
    }

    componentDidMount() {
        let ids = []
        let idsds = []
        for (let x in this.props.codeInfo.linkLogIds) {
            ids.push(x)
            idsds.push({ id: x, value: this.props.codeInfo.linkLogIds[x] })
        }

        if (ids.length > 0) {
            this.setState({ ids, idsds })
            this.props.getCodeIdsInfoMore({
                data: { ids: ids }, callback: (data) => {
                    this.setState({ datadata: data })
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUpdate(nextProps) {
    }

    renderFilterHead = (col) => {
        // const list = col.key == 'prizeType' ? prizeTypeFilter : promotionTypeFilter;
        const list = prizeTypeFilter;
        const { drawPaging, getDrawRecord, tagSearch: { codeUri, senNum } } = this.props;
        const value = drawPaging[col.key] ? drawPaging[col.key] : [];
        return (
            <DataTable.FilterableColumnHeader
                list={list}
                value={value}
                onFilter={(value) => {
                    if (codeUri && senNum) {
                        drawPaging[`${col.key}`] = value;
                        getDrawRecord({ ...drawPaging, page: 0 })
                    }
                }}
            >{col.title}</DataTable.FilterableColumnHeader>
        )
    }

    renderSortHead = (col) => {
        return (
            <DataTable.SortableColumnHeader
                order={this.state[`${col.key}Sort`]}
                sortKey={col.key}
                onSort={() => { this.handleSort(col.key, this.state[`${col.key}Sort`]) }}
            >{col.title}</DataTable.SortableColumnHeader>
        )
    }

    handleSort = (key, order) => {
        const newOrder = handSortOrder(order);
        const bridgeObg = key == 'loti' ? { lotiSort: newOrder, ctSort: null } : { lotiSort: null, ctSort: newOrder };
        const { drawPaging, getDrawRecord, tagSearch: { codeUri, senNum } } = this.props;
        drawPaging.sort = `lacrs.${key},${newOrder}`;
        if (codeUri && senNum) {
            getDrawRecord({ ...drawPaging });
        }
        this.setState(bridgeObg);
    }

    getColumns() {
        return this.state.tableColumns.map(column => {
            if (['prizeType'].includes(column.key)) {
                return {
                    ...column,
                    headerRender: this.renderFilterHead
                }
            } else if (['lotteryTime', 'cashTime'].includes(column.dataIndex)) {
                return {
                    ...column,
                    headerRender: this.renderSortHead
                }
            } else {
                return column
            }
        })
    }


    getSelect(record) {
        // let resultModals = this.state.itemArr.filter(item => item.type == name);
        // let resultModal2 = this.sortArr(resultModals);
        // if (resultModal.length >= 2) {
        this.setState({ modalVisible2: true })
        // this.props.getCodeInfoMore({ id: record.id });
        let a = {}
        let newrecord = [];
        newrecord.push(record)
        newrecord.map(item => {
            a[item.linkCode + "_" + item.id] = JSON.stringify({ ...item.dataJsonConvert, time: formatDate(item.updatedTime) });
        })
        this.getObj(a, 'okList', true)

        // }
    }

    getObj(linkLogs, type, first) {
        let newlinkLogss = {};
        for (let key in linkLogs) {
            if (linkLogs.hasOwnProperty(key)) {

                let linLog = linkLogs[key]

                // linLog = linLog.replace(/{/g, "{\"");

                // linLog = linLog.replace(/=/g, "\":\"");

                // linLog = linLog.replace(/(, |,)/g, "\",\"");

                // linLog = linLog.replace(/}/g, "\"}");

                // linLog = linLog.replace(/\"{/g, "{");

                // linLog = linLog.replace(/}\"/g, "}");

                linLog = linLog.replace(/{\"/g, "{\"");

                linLog = linLog.replace(/\"\:{/g, "\"\:{");

                linLog = linLog.replace(/\"\:\"/g, "\":\"");

                newlinkLogss[key] = JSON.parse(linLog);
            }
        }
        this.newArr(newlinkLogss, type, first);
    }
    newArr(linkLogs, type, first) {  // 拼接成list
        let itemArr = [];
        let arrAll = []
        for (let key in linkLogs) {
            if (linkLogs.hasOwnProperty(key)) {
                let item = {};
                // console.log(key.toString().includes('_') ? `${key.split('_')[0]}`)
                itemArr.push({ type: key.toString().includes('_') ? `${key.split('_')[0]}` : key, id: key.toString().includes('_') ? `${key.split('_')[1]}` : key, text: 'Id:' + (key.toString().includes('_') ? `${key.split('_')[1]}` : key), value: key.toString().includes('_') ? `${key.split('_')[1]}` : key });
                for (let k in linkLogs[key]) {
                    if (linkLogs[key].hasOwnProperty(k) && k != 'time') {
                        let ks = `${k.split('|')[1]}(${k.split('|')[0]})`;
                        let obj = {};
                        for (let m in linkLogs[key][k]) {
                            if (linkLogs[key][k].hasOwnProperty(m)) {

                                let mv = m.toString().includes('|') ? `${m.split('|')[1]}(${m.split('|')[0]})` : m;
                                let kv = k.split('|');
                                let linkLogss = (linkLogs[key][k][m] && linkLogs[key][k][m].toString().includes('|')) ? linkLogs[key][k][m].split('|') : linkLogs[key][k][m];
                                let newObj = {
                                    name: `${kv[1]}(${kv[0]})_${mv}`,
                                    time: linkLogs[key]['time'],
                                    value: (linkLogss && typeof (linkLogss) == 'object') ? `${linkLogss[1]}(${linkLogss[0]})` : linkLogss
                                }
                                let ms = m.toString().includes('|') ? `${m.split('|')[1]}(${m.split('|')[0]})` : m;
                                obj[ms] = linkLogss ? typeof (linkLogss) == 'object' ? ms.indexOf('Time') < 0 ? `${linkLogss[1]}(${linkLogss[0]})` : linkLogss[1] : linkLogss : linkLogss;
                                itemArr.push(newObj);
                            }
                        }
                        item.title = ks;
                        item.val = obj;
                        item.time = linkLogs[key]['time'];
                        item.name = key.toString().includes('_') ? `${key.split('_')[0]}` : key;
                        arrAll.push(item);

                    }
                }

            }
        }
        // 排重
        let redTableList = []
        for (var i = 0, len = arrAll.length; i < len; i++) {
            for (var j = i + 1; j < len; j++) {
                // 如果发现相同元素
                // 则 i 自增进入下一个循环比较
                if (arrAll[i].title === arrAll[j].title)
                    j = ++i;
            }
            redTableList.push(arrAll[i])
            // this.redTableList.push(arrAll[i]);
        }
        if (type == 'okList') {
            this.setState({ okList: redTableList, okListItem: itemArr })
        }
        else {
            this.setState({ failList: redTableList, failListItem: itemArr })
        }
        if (first) {
            this.tofitst(redTableList, itemArr)
        }
        // this.setState({ redTableList, itemArr })
    }

    tofitst = (data, data2) => {
        this.setState({ redTableList: data, itemArr: data2 || [] })

    }
    trtr(item) {
        let a = []
        for (let x in item) {
            let data = {
                title: x,
                value: item[x],
            }
            a.push(data)
        }
        return a
    }
    render() {
        const { prizeDetailDialogShow, expressInfoDialogShow, redPacketDialogShow, prizeDetailInfoData, expressInfoData, redpacketInfoData, datadata, redTableList, itemArr } = this.state;
        const { dloading, tagSearch, drawPaging, perPageCountList, drawTotalElements, getDrawRecord } = this.props;
        const listStyle = datadata.length > 0 && !dloading;
        const { codeUri, senNum } = tagSearch;
        return (
            <div>
                <div style={{ paddingBottom: !listStyle && 20 }}>
                    <div className="searchbar-box">
                        {/* <SearchInput
                            // className="search-input"
                            className="search-input-block"
                            placeholder="输入环境名称"
                            onChange={(value) => { changeDrawSearch({ uuid: value }) }}
                            // onClear={() => { changeDrawSearch({ uuid: '' }) }}
                            onSearch={() => {
                                const { codeUri, senNum } = tagSearch;
                                if (codeUri && senNum) {
                                    drawPaging.isSearch = false;
                                    if (drawPaging.uuid) {
                                        drawPaging.isSearch = true;
                                    }
                                    getDrawRecord({ ...drawPaging })
                                }
                            }}
                            value={drawPaging.uuid}
                        /> */}
                    </div>
                    <DataTable
                        style={{ marginBottom: 20 }}
                        columns={this.getColumns()}
                        data={datadata}
                        rowKey="id"
                        messageRender={({ data, error }) => {
                            if (error != null) { return error.msg; }
                            if (data == null || data.length === 0) { return <span className="text-weak">暂无数据</span>; }
                        }}
                        loading={dloading}
                    />
                    {/* {listStyle && <div className="page-padding20">
                        <Pager
                            total={drawTotalElements}
                            current={drawPaging.page}
                            perPageCount={drawPaging.size}
                            perPageCountList={perPageCountList}
                            onPageChange={e => { getDrawRecord({ ...drawPaging, page: e }) }}
                            onPerPageChange={e => { getDrawRecord({ ...drawPaging, size: e, page: 0 }) }} // 每页大小
                        />
                    </div>} */}
                </div>
                {/* 弹窗 */}
                <Dialog
                    title={"明细"}
                    visible={this.state.modalVisible2}
                    onCancel={() => { this.setState({ modalVisible2: false }); }}
                    onOk={() => { this.setState({ modalVisible2: false }); }}
                    style={{ width: "685px" }}
                    className="AcquisitionInfo-modal"
                >


                    {redTableList.map((item, index) => {

                        return <table key={index} className="table-list" style={{ marginBottom: 20 }} cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr >
                                    {/* <th colSpan="2">{item.title} <span style={{ textDecoration: 'underline', color: '#2d8cf0', cursor: 'pointer', float: 'right' }} onClick={() => { this.getSelect(item.name) }}>上传明细</span></th> */}
                                </tr>
                                <tr style={{ fontWeight: 'bold' }}>
                                    <td style={{ width: '40%' }}>名称</td>
                                    <td>内容</td>
                                </tr>

                                {this.trtr(item.val).map((items, index2) => {
                                    return <tr key={index2}>
                                        <td>{items.title}</td>
                                        <td
                                            style={this.state.itemArr.filter(itemss => itemss.name == item.title + "_" + items.title).length >= 2 ? {
                                                textDecoration: 'underline',
                                                color: '#2d8cf0',
                                                cursor: 'pointer',
                                            } : {}}
                                            onClick={() => { this.getChildern(item.title + "_" + items.title) }}
                                        // style="getStyle(item.title +'_'+ key)"
                                        // click="getChildern(item.title +'_'+ key)"
                                        >{items.value}</td>
                                    </tr>
                                })
                                }



                            </tbody>
                        </table>
                    })}


                </Dialog>

            </div>
        )
    }
}

export default StateInfo;

