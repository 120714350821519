import React from 'react';
import { connect } from 'react-redux';
import { Pager, DataTable, SearchInput, Button, notification } from 'tyb';
const { loadlotteryRecordList, updateEnterprisesSelectdId, updateLotteryRecoredSearchUuid, exportLotteryRecordList } = iceStarkData.store.get('commonAction')?.queryTools;
const { loadRedpacketQueryInfo } = iceStarkData.store.get('commonAction')?.wechat;
import { RedpacketInfoDialog, ExpressInfoDialog, PrizeDetailDialog } from '../index';
import { lotteryTableHead, pointTableHead, cardTableHead, usertableHead, } from '../../UserQuery/UserQueryTableHead';
import { handSortOrder } from 'ucode-utils';
import '../../UserQuery/UserQuery.scss';
import { NewSelect } from 'ucode-components';
import { DownLoadAnimate } from 'ucode-components'
const prizeTypeFilter = [
  { text: '微信红包', value: 'RED_PACKET' },
  { text: '实物', value: 'PHYSICAL' },
  { text: '积分', value: 'POINT' },
  { text: '优惠券', value: 'COUPON' },
  { text: '虚拟卡', value: 'CARD' },
  { text: '零钱转账', value: 'CHANGE_OF_MONEY' },
  { text: '核销', value: 'WRITE_OFF' }
];
const promotionTypeFilter = [
  { text: '互动抽奖', value: 'INTERACT' },
  { text: 'C端扫码', value: 'QR_CODE' },
  { text: '邀请抽奖', value: 'INVITE' },
  { text: '签到活动', value: 'CHECK_IN' },
  { text: '传播活动', value: 'DISSEMINATE_PROMOTION' },
  { text: 'B端扫码', value: 'OPEN_BOX' },
  { text: '会员任务', value: 'MEMBER_TASK' },
  
  { text: '宴会活动', value: 'BANQUET_PROMOTION' },
];

@connect(
  state => {
    return {
      ...state.queryTools,
      ...state.enterprises,
      ...state.wechat,
      openIds: state.users.openIds,
    };
  },
  {
    updateEnterprisesSelectdId,
    updateLotteryRecoredSearchUuid,
    loadlotteryRecordList: loadlotteryRecordList.REQUEST,
    loadRedpacketQueryInfo: loadRedpacketQueryInfo.REQUEST,
    exportLotteryRecordList: exportLotteryRecordList.REQUEST,
  }
)
class UserQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 弹窗信息
      redPacketDialogShow: false,
      redpacketInfoData: {},
      expressInfoDialogShow: false,
      expressInfoData: {},
      prizeDetailDialogShow: false,
      prizeDetailInfoData: {},
      // 列表排序
      lotteryTimeSort: null,
      cashTimeSort: null,
      lotteryHit: true,

      animateShow: 0,
    };
  }

  componentDidMount() { }
  tableHeadFilter = param => {
    const { list, titleContent, value, filterFun } = param;
    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={value}
        onFilter={value => { filterFun(value); }}
      >
        {titleContent}
      </DataTable.FilterableColumnHeader>
    );
  };
  handleSort = (curOrder, key) => {
    const { loadlotteryRecordPage = {}, loadlotteryRecordList } = this.props;
    const newOrder = handSortOrder(curOrder);
    const bridgeObg = key == 'lotteryTime' ? { lotteryTimeSort: newOrder, cashTimeSort: null } : { lotteryTimeSort: null, cashTimeSort: newOrder };
    const newSort = key == 'lotteryTime' ? `lacrs.loti,${newOrder}` : `lacrs.ct,${newOrder}`;
    loadlotteryRecordList({ loadlotteryRecordPage: { ...loadlotteryRecordPage, page: 0, sort: newSort }, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit });

    this.setState(bridgeObg);
  };
  // 排序 sort
  tableHeadSort = col => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state[`${col.key}Sort`]}
        sortKey={col.key}
        onSort={() => {
          this.handleSort(this.state[`${col.key}Sort`], col.key);
        }}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    );
  };
  formatTableHead = table => {
    const tableHeadobj = { usertableHead, lotteryTableHead, pointTableHead, cardTableHead, };

    return tableHeadobj[table].map(column => {
      if (column.key === 'poolPrizeId') {
        return {
          ...column,
          render: (value, record) => {
            let text = record.awardType == 'INTELLIGENT' ? (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || `${record.awardName}(暂未推荐奖品)`) : '未中奖' : (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || '未中奖') : '未中奖';
            return (
              <div>
                <div
                  className="ellipsis cursor-handle"
                  style={{ color: '#006EFF', width: '100%' }}
                  onClick={() => {
                    this.setState({
                      prizeDetailDialogShow: true,
                      prizeDetailInfoData: {
                        ...record,
                        text: record.awardType == 'INTELLIGENT' ? (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || `${record.awardName}(暂未推荐奖品)`) : '未中奖' : (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || '未中奖') : '未中奖'
                      }
                    })
                  }}>{text}</div>
                {text != '未中奖' && <div>{record.prizeId}</div>}
              </div>
            )
          }
        };
      } else if (['lotteryTime', 'cashTime'].includes(column.key)) {
        return {
          ...column,
          headerRender: col =>
            this.tableHeadSort(col),
        };
      } else if (['prizeType', 'promotionType'].includes(column.key)) {
        return {
          ...column,
          headerRender: () => {
            const { loadlotteryRecordList, loadlotteryRecordPage } = this.props;
            return this.tableHeadFilter({
              list: column.key == 'prizeType' ? prizeTypeFilter : promotionTypeFilter,
              titleContent: column.key == 'prizeType' ? '奖品类型' : '活动来源',
              value: loadlotteryRecordPage[column.key] ? loadlotteryRecordPage[column.key].split(',') : [],
              filterFun: (value) => {
                const newValue = value.join(',');
                loadlotteryRecordList({ loadlotteryRecordPage: { ...loadlotteryRecordPage, [column.key]: newValue, page: 0 }, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit });
              },
            })
          }
        };
      } else if (column.key === 'action') {
        return {
          ...column,
          render: (value, record) => {
            const { redPacketCashInfo = {} } = record;
            const { currentAppid, currentOpenId } = this.props;
            return (
              <div style={{ color: '#006EFF' }}>
                <span
                  className="cursor-handle"
                  onClick={() => { this.setState({ expressInfoData: record.expressInfo, expressInfoDialogShow: true, }); }}
                  style={{ display: 'inline-block', marginRight: '10px' }}
                >提交信息</span>
                {(record.prizeType == 'RED_PACKET' || record.prizeType == 'CHANGE_OF_MONEY') && redPacketCashInfo.merchantBillNo && <span
                  className="cursor-handle"
                  onClick={() => {
                    this.props.loadRedpacketQueryInfo({ prizeType: record.prizeType, appId: currentAppid, merchantBillNo: redPacketCashInfo.merchantBillNo || '', openId: currentOpenId, merchantId: redPacketCashInfo.merchantId || '' });
                    this.setState({ redPacketDialogShow: true, });
                  }}
                  style={{ display: 'inline-block', marginRight: '10px' }}
                >商户订单查询</span>}
                {record.prizeStatus == 'CLAIM_FAIL' && <span className="cursor-handle" onClick={() => this.setState({ expressInfoDialogShow: true, expressInfoData: record })}>失败原因</span>}
              </div>
            );
          },
        };
      } else {
        return column;
      }
    });
  };

  //导出
  templateExport = () => {
    const { drawPaging } = this.props;

    this.props.exportLotteryRecordList({
      // ...drawPaging,
      callback: () => {
        this.setState({ animateShow: this.state.animateShow + 1 })
      }
    });
    notification.success('导出成功');


  }

  render() {
    const { redPacketDialogShow, redpacketInfoData, expressInfoDialogShow, expressInfoData, prizeDetailDialogShow, prizeDetailInfoData } = this.state;
    const { currentOpenId = '', tabListCurrentTab, lotteryRecordList, loadlotteryRecordPage = {}, lotteryRecoredSearchUuid, lotteryRecordLoad, loadlotteryRecordList, updateLotteryRecoredSearchUuid } = this.props;
    return (
      <div>
        <div className="records-list-head" style={{ overflow: 'visible' }}>

          {/* <div className="search-input-block">
            <SearchInput
              onChange={value => { updateLotteryRecoredSearchUuid({ lotteryRecoredSearchUuid: value }); }}
              onSearch={value => { loadlotteryRecordList({ lotteryRecoredSearchUuid: value, loadlotteryRecordPage: { ...loadlotteryRecordPage }, lotteryRecoredSearchUuid: value }); }}
              value={lotteryRecoredSearchUuid}
              placeholder="流水号"
            />
            <SearchInput
              onChange={value => { updateLotteryRecoredSearchUuid({ lotteryRecoredSearchUuid: value }); }}
              onSearch={value => { loadlotteryRecordList({ lotteryRecoredSearchUuid: value, loadlotteryRecordPage: { ...loadlotteryRecordPage }, lotteryRecoredSearchUuid: value }); }}
              value={lotteryRecoredSearchUuid}
              placeholder="流水号2"
            />
          </div> */}



          <div className="searchbar-box" style={{ marginTop: 0, overflow: 'visible' }}>
            {lotteryRecordList.length > 0 && <Button className="tc-15-btn export" onClick={(e) => this.templateExport(true)} style={{ marginRight: 10 }}>导出</Button>}
            <span className="table-title">openID: {currentOpenId}</span>
            <SearchInput
              // className="search-input"
              className="search-input-block"
              placeholder="流水号"
              onChange={(value) => { updateLotteryRecoredSearchUuid({ lotteryRecoredSearchUuid: value }); }}
              // onClear={() => { changeScanSearch({ keyWords: '' }) }}
              onSearch={value => { loadlotteryRecordList({ lotteryRecoredSearchUuid: value, loadlotteryRecordPage: { ...loadlotteryRecordPage }, lotteryRecoredSearchUuid: value, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit }); }}
              value={lotteryRecoredSearchUuid}
            />
            <NewSelect
              className="table-search-select"
              style={{ float: 'right', marginRight: 8 }}
              value={this.state.lotteryHit}
              list={[{ text: '全部', value: 'all' }, { text: '中奖', value: true }, { text: '未中奖', value: 2 }]}
              // placeholder="方式"
              onChange={(value) => {
                this.setState({ lotteryHit: value })
              }}
            />
            <div style={{ clear: 'both' }}></div>
          </div>









        </div>
        <div className="lottery-record-box">
          <DataTable
            columns={this.formatTableHead('lotteryTableHead')}
            data={lotteryRecordList}
            rowKey="id"
            loading={lotteryRecordLoad}
            style={{ width: '100%', maxWidth: '100%', border: '1px solid #E2E2E2', borderBottom: 'none' }}
            messageRender={({ data, error }) => {
              if (error != null) {
                return error.msg;
              }
              if (data == null || data.length === 0) {
                return <span className="text-weak">暂无数据</span>;
              }
            }}
          />
          {lotteryRecordList.length > 0 && <Pager
            total={loadlotteryRecordPage.totalElements}
            current={loadlotteryRecordPage.page}
            // perPageCountList={perPageCountList}
            perPageCount={loadlotteryRecordPage.size}
            onPageChange={value => { loadlotteryRecordList({ loadlotteryRecordPage: { ...loadlotteryRecordPage, page: value }, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit }); }}
            onPerPageChange={value => { loadlotteryRecordList({ loadlotteryRecordPage: { ...loadlotteryRecordPage, size: value }, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit }); }}
          />}
        </div>
        <RedpacketInfoDialog
          redPacketDialogShow={redPacketDialogShow}
          redpacketInfoData={redpacketInfoData}
          handleCloseDialog={() => {
            this.setState({ redPacketDialogShow: false });
          }}
        />
        <ExpressInfoDialog
          expressInfoDialogShow={expressInfoDialogShow}
          expressInfoData={expressInfoData}
          handleCloseDialog={() => {
            this.setState({ expressInfoDialogShow: false });
          }}
        />
        <PrizeDetailDialog
          prizeDetailDialogShow={prizeDetailDialogShow}
          prizeDetailInfoData={prizeDetailInfoData}
          handleCloseDialog={() => {
            this.setState({ prizeDetailDialogShow: false });
          }}
        />
        <DownLoadAnimate animateIcon='.searchbar-box .export' animateShow={this.state.animateShow}></DownLoadAnimate>
      </div>
    );
  }
}

export default UserQuery;
