import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Button } from 'tyb';
import './userInfoDialog.scss';
const { loadUserOne } = iceStarkData.store.get('commonAction')?.antiblack;
const GENDER = {
  UNKNOWN: '未知',
  MAN: '男',
  WOMAN: '女'
}

@connect(
  state => {
    return {
      ...state.queryTools,
      openIds: state.users.openIds,
    }
  },
  {
    // loadQueryUserMetadata: loadQueryUserMetadata.REQUEST,
    loadUserOne: loadUserOne.REQUEST,
  }
)
class UserInfoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      antiblack: {
        name: '正常用户',

      },
    };
  }


  componentDidMount() {
    this.props.loadUserOne({
      user: this.props.userInfoData.openId,
      eseId: this.props.userInfoData.eseId || this.props.openIds.eseId,
      callback: (data) => {
        if (data && data.id) {
          this.setState({
            antiblack: {
              name: data.protectType == 'WHITE' ? '白名单' : '黑名单',
              time: data.unBlackTime,
              protectStatus: data.protectStatus,
            }
          })
        }

      }
    })
  }

  componentDidUpdate(prevProps) {
  }
  handleFooterRender = () => {
    const { handleCloseDialog } = this.props;
    return (
      <div className="tc-15-rich-dialog-ft-btn-wrap">
        <Button className="weak" onClick={() => { handleCloseDialog() }}>关闭</Button>
      </div>
    );
  }

  render() {
    const { antiblack } = this.state;
    const { userInfoDialogShow, handleCloseDialog, userInfoData = {} } = this.props;
    return (
      <Dialog
        className="userInfo-dialog"
        style={{ width: '365px', minWidth: '365px' }}
        title="基本信息"
        visible={userInfoDialogShow}
        footerRender={this.handleFooterRender}
        onCancel={handleCloseDialog}
      >
        <div className="userInfo-dialog-content">
          {userInfoData.avatarUrl && <img className="user-avatarUrl" src={userInfoData.avatarUrl}></img>}
          {!userInfoData.avatarUrl && <div className="user-avatarUrl">微信头像</div>}
          <ul>
            <li><span>用户类别</span><span>{antiblack.name}</span></li>
            {antiblack.name == '黑名单' && <li style={{ marginTop: -15, color: '#999' }}><span></span><span>{antiblack.protectStatus == 'ENABLE' ? '已封停至' + antiblack.time : '已解封'}</span></li>}
            <li><span>真实姓名</span><span>{userInfoData.realName}</span></li>
            <li><span>微信昵称</span><span>{userInfoData.nickname}</span></li>
            <li><span>手机号</span><span>{userInfoData.phoneNumber}</span></li>
            <li><span>性别</span><span>{userInfoData.gender ? GENDER[userInfoData.gender] : ''}</span></li>
            <li><span>身份证</span><span>{userInfoData.idCardNo}</span></li>
            <li><span>区域</span><span>{userInfoData.provinceName}{userInfoData.cityName}{userInfoData.districtName}</span></li>
            <li><span>地址</span><span>{userInfoData.address}</span></li>
          </ul>
        </div>
      </Dialog>
    )
  }
}


export default UserInfoDialog;