import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
import { Dialog } from 'tyb';
import './styles.scss';
import enums from '@/assets/enum.json';
import { formatDate } from 'ucode-utils';
const { getCodeInfoMore } = iceStarkData.store.get('commonAction')?.queryTools;
import { NewSelect } from 'ucode-components';
@connect(
    (state) => {
        return {
            codeInfo: state.queryTools.codeInfo,
            codeQuerySearch: state.queryTools.codeQuerySearch,
            savePrevquery: state.queryTools.savePrevquery,
            openIds: state.users.openIds,
            codeInfoMore: state.queryTools.codeInfoMore,
        }
    },
    {
        getCodeInfoMore: getCodeInfoMore.REQUEST,
    }
)

class AcquisitionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redTableList: [],
            itemArr: [],
            modalVisible: false,
            modalVisible2: false,
            resultModal: [],

            resultModal2: [],
            selectId: '',
        };
    }
    getObj(linkLogs) {
        let newlinkLogss = {};
        for (let key in linkLogs) {
            if (linkLogs.hasOwnProperty(key)) {

                let linLog = linkLogs[key]

                // linLog = linLog.replace(/{/g, "{\"");

                // linLog = linLog.replace(/=/g, "\":\"");

                // linLog = linLog.replace(/(, |,)/g, "\",\"");

                // linLog = linLog.replace(/}/g, "\"}");

                // linLog = linLog.replace(/\"{/g, "{");

                // linLog = linLog.replace(/}\"/g, "}");

                linLog = linLog.replace(/{\"/g, "{\"");

                linLog = linLog.replace(/\"\:{/g, "\"\:{");

                linLog = linLog.replace(/\"\:\"/g, "\":\"");

                newlinkLogss[key] = JSON.parse(linLog);
            }
        }
        this.newArr(newlinkLogss);
    }
    newArr(linkLogs) {  // 拼接成list
        let itemArr = [];
        let arrAll = []
        for (let key in linkLogs) {
            if (linkLogs.hasOwnProperty(key)) {
                let item = {};
                // console.log(key.toString().includes('_') ? `${key.split('_')[0]}`)
                itemArr.push({ type: key.toString().includes('_') ? `${key.split('_')[0]}` : key, id: key.toString().includes('_') ? `${key.split('_')[1]}` : key, text:'Id:'+( key.toString().includes('_') ? `${key.split('_')[1]}` : key), value: key.toString().includes('_') ? `${key.split('_')[1]}` : key });
                for (let k in linkLogs[key]) {
                    if (linkLogs[key].hasOwnProperty(k) && k != 'time') {
                        let ks = `${k.split('|')[1]}(${k.split('|')[0]})`;
                        let obj = {};
                        for (let m in linkLogs[key][k]) {
                            if (linkLogs[key][k].hasOwnProperty(m)) {

                                let mv = m.toString().includes('|') ? `${m.split('|')[1]}(${m.split('|')[0]})` : m;
                                let kv = k.split('|');
                                let linkLogss = (linkLogs[key][k][m] && linkLogs[key][k][m].toString().includes('|')) ? linkLogs[key][k][m].split('|') : linkLogs[key][k][m];
                                let newObj = {
                                    name: `${kv[1]}(${kv[0]})_${mv}`,
                                    time: linkLogs[key]['time'],
                                    value: (linkLogss && typeof (linkLogss) == 'object') ? `${linkLogss[1]}(${linkLogss[0]})` : linkLogss
                                }
                                let ms = m.toString().includes('|') ? `${m.split('|')[1]}(${m.split('|')[0]})` : m;
                                obj[ms] = linkLogss ? typeof (linkLogss) == 'object' ? ms.indexOf('Time') < 0 ? `${linkLogss[1]}(${linkLogss[0]})` : linkLogss[1] : linkLogss : linkLogss;
                                itemArr.push(newObj);
                            }
                        }
                        item.title = ks;
                        item.val = obj;
                        item.time = linkLogs[key]['time'];
                        item.name = key.toString().includes('_') ? `${key.split('_')[0]}` : key;
                        arrAll.push(item);

                    }
                }

            }
        }
        // 排重
        let redTableList = []
        for (var i = 0, len = arrAll.length; i < len; i++) {
            for (var j = i + 1; j < len; j++) {
                // 如果发现相同元素
                // 则 i 自增进入下一个循环比较
                if (arrAll[i].title === arrAll[j].title)
                    j = ++i;
            }
            redTableList.push(arrAll[i])
            // this.redTableList.push(arrAll[i]);
        }
        this.setState({ redTableList, itemArr })
    }


    trtr(item) {
        let a = []
        for (let x in item) {
            let data = {
                title: x,
                value: item[x],
            }
            a.push(data)
        }
        return a
    }

    getChildern(name) {
        let resultModals = this.state.itemArr.filter(item => item.name == name);
        let resultModal = this.sortArr(resultModals);
        if (resultModal.length >= 2) {
            this.setState({ modalVisible: true, resultModal })
        }
    }
    getSelect(name) {
        let resultModals = this.state.itemArr.filter(item => item.type == name);
        let resultModal2 = this.sortArr(resultModals);
        // if (resultModal.length >= 2) {
        this.setState({ modalVisible2: true, resultModal2, selectId: resultModal2[resultModal2.length - 1].id })
        this.props.getCodeInfoMore({ id: resultModal2[resultModal2.length - 1].id });
        // }
    }


    sortArr(arr) { // 排序
        for (let i = 0; i < arr.length - 1; i++) {
            for (var j = i + 1; j < arr.length; j++) {
                if (arr[i].time < arr[j].time) { //如果后面的数据比前面的大就交换  
                    var temp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = temp;
                }
            }
        }
        return arr;
    }


    handleChangeSelect = (value) => {
        this.setState({
            selectId: value
        })
        this.props.getCodeInfoMore({ id: value });
    }


    componentDidMount() {
        this.getObj(this.props.codeInfo.linkLog)
    }
    render() {
        const { redTableList } = this.state;
        return (
            <div>
                <div className="code-info-box">
                    {Object.keys(redTableList).length == 0 ?
                        <div className="text-weak tip-text">输入码序号或码链接进行查询</div> :

                        redTableList.map((item, index) => {

                            return <table key={index} className="table-list" style={{ marginBottom: 20 }} cellSpacing="0" cellPadding="0">
                                <tbody>
                                    <tr >
                                        <th colSpan="2">{item.title} <span style={{  textDecoration: 'underline',color: '#2d8cf0',cursor: 'pointer',float:'right'}} onClick={() => { this.getSelect(item.name) }}>上传明细</span></th>
                                    </tr>
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td style={{ width: '40%' }}>名称</td>
                                        <td>内容</td>
                                    </tr>

                                    {this.trtr(item.val).map((items, index2) => {
                                        return <tr key={index2}>
                                            <td>{items.title}</td>
                                            <td
                                                style={this.state.itemArr.filter(itemss => itemss.name == item.title + "_" + items.title).length >= 2 ? {
                                                    textDecoration: 'underline',
                                                    color: '#2d8cf0',
                                                    cursor: 'pointer',
                                                } : {}}
                                                onClick={() => { this.getChildern(item.title + "_" + items.title) }}
                                            // style="getStyle(item.title +'_'+ key)"
                                            // click="getChildern(item.title +'_'+ key)"
                                            >{items.value}</td>
                                        </tr>
                                    })
                                    }



                                </tbody>
                            </table>

                        })

                    }
                </div>










                <Dialog
                    title={"字段更新历史"}
                    visible={this.state.modalVisible}
                    onCancel={() => { this.setState({ modalVisible: false }); }}
                    onOk={() => { this.setState({ modalVisible: false }); }}
                    style={{ width: "485px" }}
                    className="AcquisitionInfo-modal"
                >
                    <table
                        cellSpacing="0"
                        cellPadding="0"
                        className="table-list"
                    >
                        <tbody>
                            <tr>
                                <td style={{ width: '40%' }}>更新时间</td>
                                <td>更新内容</td>
                            </tr>
                            {this.state.resultModal.map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.time && item.time != 'null' ? item.time : ''}</td>
                                    <td>{item.value}</td>
                                </tr>
                            })
                            }
                        </tbody>
                    </table>

                </Dialog>



                <Dialog
                    title={"上传明细"}
                    visible={this.state.modalVisible2}
                    onCancel={() => { this.setState({ modalVisible2: false }); }}
                    onOk={() => { this.setState({ modalVisible2: false }); }}
                    style={{ width: "685px" }}
                    className="AcquisitionInfo-modal"
                >
                    <NewSelect
                    
                        style={{ width: '250px' }}
                        value={this.state.selectId}
                        list={this.state.resultModal2}
                        onChange={this.handleChangeSelect}
                    >
                    </NewSelect>
                    <table
                        cellSpacing="0"
                        cellPadding="0"
                        className="table-list"
                    >
                        <tbody>
                            <tr>
                                <td style={{ width: '40%' }}>上传字段</td>
                                <td>明细内容</td>
                            </tr>
                            <tr>
                                <td >文件名</td>
                                <td>{this.props.codeInfoMore.fileName}</td>
                            </tr>
                            <tr>
                                <td >环节数据</td>
                                <td>{JSON.stringify(this.props.codeInfoMore.dataJson)}</td>
                            </tr>
                            <tr>
                                <td >文件状态</td>
                                {/* <td>{this.props.codeInfoMore.fileStatus}</td> */}
                                <td>{enums.fileStatus[this.props.codeInfoMore.fileStatus] ? enums.fileStatus[this.props.codeInfoMore.fileStatus].name : ''}</td>
                            </tr>
                            <tr>
                                <td >最后更新时间</td>
                                <td>{ this.props.codeInfoMore.updatedTime ? formatDate(this.props.codeInfoMore.updatedTime) : ''}</td>
                            </tr>
                            <tr>
                                <td >上传码数</td>
                                <td>{this.props.codeInfoMore.commitCnt}</td>
                            </tr>
                            <tr>
                                <td >执行码数</td>
                                <td>{this.props.codeInfoMore.optionCnt}</td>
                            </tr>
                            <tr>
                                <td >文件类型</td>
                                <td>{enums.fileType[this.props.codeInfoMore.fileType] ? enums.fileType[this.props.codeInfoMore.fileType].name : ''}</td>
                            </tr>
                            <tr>
                                <td >操作码状态</td>
                                <td>{enums.optionStatus[this.props.codeInfoMore.optionStatus] ? enums.optionStatus[this.props.codeInfoMore.optionStatus].name : ''}</td>

                            </tr>
                            <tr>
                                <td >前置码状态</td>
                                <td>
                                    {this.props.codeInfoMore ? this.props.codeInfoMore.limitStatus ? this.props.codeInfoMore.limitStatus.map((item, index) => {
                                        return enums.limitStatus[item] ? enums.limitStatus[item].name : ''
                                    }).join(',') : '' : ''
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td >码数据</td>
                                <td>{this.props.codeInfoMore.tags ? this.props.codeInfoMore.tags.join(',') : []}</td>
                            </tr>
                            <tr>
                                <td >异常信息</td>
                                <td>{this.props.codeInfoMore.errMsg}</td>
                            </tr>
                            {/* {JSON.stringify(this.props.codeInfoMore)} */}
                            {/* {this.props.codeInfoMore.map((item, index) => {
                            return <tr key={index}>
                                <td>{item.time && item.time != 'null' ? item.time : ''}</td>
                                <td>{item.value}</td>
                            </tr>
                        }) */}

                        </tbody>
                    </table>

                </Dialog>

            </div>
        )
    }
}


export default AcquisitionInfo;