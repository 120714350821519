import React from 'react';
import { Dialog, Button } from 'tyb';
import { connect } from "react-redux";
import './prizeDetailDialog.scss';
import { toNotWinType } from 'ucode-utils';
import enums from '@/assets/enum.json';
const { getWriteOffPolicySelection } = iceStarkData.store.get('commonAction')?.whiteOff;
import { formatDate } from 'ucode-utils';

@connect(
  state => {
    return {
      writeOffPolicySelection: state.whiteOff.writeOffPolicySelection || [],
    }
  },
  {
    getWriteOffPolicySelection: getWriteOffPolicySelection.REQUEST,
  }
)
class PrizeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {

    this.props.getWriteOffPolicySelection({ status: 'ENABLE' })


  }

  componentDidUpdate(prevProps) { }
  handleFooterRender = () => {
    const { handleCloseDialog } = this.props;
    return (
      <div className="tc-15-rich-dialog-ft-btn-wrap">
        <Button className="weak" onClick={() => { handleCloseDialog(); }} > 关闭 </Button>
      </div>
    );
  };

  render() {
    const { prizeDetailDialogShow, handleCloseDialog, prizeDetailInfoData = {}, } = this.props;
    return (
      <Dialog
        className="prizedetail-dialog"
        style={{ width: '365px', minWidth: '365px' }}
        title={(!prizeDetailInfoData.notWinType || prizeDetailInfoData.notWinType == 'WINNING') ? '奖品明细' : '未中奖明细'}
        visible={prizeDetailDialogShow}
        footerRender={this.handleFooterRender}
        onCancel={handleCloseDialog}
      >
        <div className="prizedetail-dialog-content">
          <ul>
            {prizeDetailInfoData.text != '未中奖' && prizeDetailInfoData.poolPrizeId && <li><span>奖品名称</span><span style={{ cursor: 'default' }} title={prizeDetailInfoData.prizeRemark ? `备注：${prizeDetailInfoData.prizeRemark}` : ''}>{prizeDetailInfoData.poolPrizeName} ({prizeDetailInfoData.prizeId})</span></li>}
            {prizeDetailInfoData.text != '未中奖' && prizeDetailInfoData.poolPrizeId && <li><span>所属奖池</span><span>{prizeDetailInfoData.poolName}{prizeDetailInfoData.poolVersion && `_V${prizeDetailInfoData.poolVersion}`} ({prizeDetailInfoData.poolId})</span></li>}
            {prizeDetailInfoData.text != '未中奖' && prizeDetailInfoData.poolType && <li><span>奖池类型</span><span>{prizeDetailInfoData.poolType == 'NEST' ? '嵌套奖池' : prizeDetailInfoData.poolType == 'SHARD' ? '拆分奖池' : '普通奖池'}</span></li>}
            {prizeDetailInfoData.text != '未中奖' && prizeDetailInfoData.poolType == 'NEST' && <li><span>嵌套奖池</span><span>{prizeDetailInfoData.nestPoolName} ({prizeDetailInfoData.nestPoolId})</span></li>}


            {prizeDetailInfoData.text != '未中奖' && prizeDetailInfoData.poolPrizeId && <li><span>奖品价值</span><span>{prizeDetailInfoData.amount ? prizeDetailInfoData.amount / 100 : ''}</span></li>}
            {prizeDetailInfoData.notWinType && prizeDetailInfoData.notWinType != 'WINNING' && <li><span>未中奖原因</span><span>{prizeDetailInfoData.notWinType && toNotWinType(prizeDetailInfoData.notWinType)}</span></li>}
            {prizeDetailInfoData.promotionType == 'QR_CODE' && <li><span>抽奖方式</span><span>{prizeDetailInfoData.lotteryType ? (prizeDetailInfoData.lotteryType === 'ONE' ? '一码一抽' : '一码多抽') : ''} </span> </li>}
            {prizeDetailInfoData.promotionType != 'MEMBER_RIGHTS' && <li><span>所属活动</span><span>{prizeDetailInfoData.promotionName}{prizeDetailInfoData.promotionVersion && `_V${prizeDetailInfoData.promotionVersion}`} ({prizeDetailInfoData.promotionId})</span></li>}
            {prizeDetailInfoData.promotionType != 'MEMBER_RIGHTS' && <li><span>活动类型</span><span>{prizeDetailInfoData.promotionType == 'OPEN_BOX' ? 'B端扫码' : prizeDetailInfoData.promotionType == 'QR_CODE' ? 'C端扫码' : enums.promotionType[prizeDetailInfoData.promotionType] ? enums.promotionType[prizeDetailInfoData.promotionType].name : ''}</span></li>}

            {prizeDetailInfoData.promotionType == 'MEMBER_RIGHTS' && <li><span>所属体系</span><span>{prizeDetailInfoData.systemName}{prizeDetailInfoData.systemVersion && `_V${prizeDetailInfoData.systemVersion}`} ({prizeDetailInfoData.systemId})</span></li>}

            {prizeDetailInfoData.text != '未中奖' && (prizeDetailInfoData.couponCashInfo || prizeDetailInfoData.writerOffInfo) && <li><span>
              {prizeDetailInfoData.couponCashInfo ? '券号' : '核销码'}</span>
              <span>
                {prizeDetailInfoData.couponCashInfo ?
                  <React.Fragment>
                    {prizeDetailInfoData.couponCashInfo.code ? <React.Fragment>{prizeDetailInfoData.couponCashInfo.code}<br></br></React.Fragment> : ''}
                    {prizeDetailInfoData.couponCashInfo.password ? <React.Fragment>{prizeDetailInfoData.couponCashInfo.password}<br></br></React.Fragment> : ''}
                    {prizeDetailInfoData.couponCashInfo.thirdCode ? <React.Fragment>{prizeDetailInfoData.couponCashInfo.thirdCode}<br></br></React.Fragment> : ''}
                    {prizeDetailInfoData.couponCashInfo.effectiveTime ? <React.Fragment>
                      {formatDate(prizeDetailInfoData.couponCashInfo.effectiveTime.indexOf(' ') >= 0 ? prizeDetailInfoData.couponCashInfo.effectiveTime : new Date(parseInt(prizeDetailInfoData.couponCashInfo.effectiveTime)))}<br></br>
                    </React.Fragment> : ''}
                    {prizeDetailInfoData.couponCashInfo.expiringTime ? <React.Fragment>
                      {formatDate(prizeDetailInfoData.couponCashInfo.effectiveTime.indexOf(' ') >= 0 ? prizeDetailInfoData.couponCashInfo.expiringTime : new Date(parseInt(prizeDetailInfoData.couponCashInfo.expiringTime)))}
                    </React.Fragment> : ''}

                  </React.Fragment> :
                  prizeDetailInfoData.writerOffInfo?.couponCodeUrl ? prizeDetailInfoData.writerOffInfo?.couponCodeUrl || '' : prizeDetailInfoData.writeOffRecord?.orderNum || ''}
              </span>
            </li>}
            {prizeDetailInfoData.prizeType == 'WRITE_OFF' && <li><span>核销政策</span><span> {prizeDetailInfoData.writerOffInfo ? this.props.writeOffPolicySelection.filter(key => key.value == prizeDetailInfoData.writerOffInfo.writeOffWordsId)[0] ? this.props.writeOffPolicySelection.filter(key => key.value == prizeDetailInfoData.writerOffInfo.writeOffWordsId)[0].name : '' : ''}</span></li>}
            <li><span>GPS</span><span>{prizeDetailInfoData.gpsAddress}</span></li>
            <li><span>命中策略ID</span><span>{prizeDetailInfoData.planStrategyId}</span></li>
            <li><span>执行方案</span><span>{prizeDetailInfoData.promotionType == 'MEMBER_RIGHTS' ? '' : (prizeDetailInfoData.planName || '') + '(' + prizeDetailInfoData.planId + ')'}</span></li>
            {(!prizeDetailInfoData.notWinType || prizeDetailInfoData.notWinType == 'WINNING') && <li><span>发放方式</span><span>{prizeDetailInfoData.incentiveId ? `${prizeDetailInfoData.incentiveName} 激励发放` : '常规发放'}</span></li>}
            {(!prizeDetailInfoData.notWinType || prizeDetailInfoData.notWinType == 'WINNING') && <li><span>发放信息</span><span>{prizeDetailInfoData.incentiveId ? prizeDetailInfoData.incentiveEseSeqNum : '无'}</span></li>}
            {prizeDetailInfoData.smugglingReason && <li><span>命中原因</span><span>{prizeDetailInfoData.smugglingReason}</span></li>}
          </ul>
        </div>
      </Dialog >
    );
  }
}

export default PrizeDetail;
