import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { getDrawRecord, changeDrawSearch, changeQuery, drawRecordExport } = iceStarkData.store.get('commonAction')?.queryTools;
const { loadRedpacketQueryInfo } = iceStarkData.store.get('commonAction')?.wechat;
import { handSortOrder } from 'ucode-utils';
import { formatDate } from 'ucode-utils';
import enums from '@/assets/enum.json';
import { NewSelect } from 'ucode-components';
import { SearchBox, SearchInput, DataTable, Pager, Button, notification } from 'tyb';
import { RedpacketInfoDialog, ExpressInfoDialog, PrizeDetailDialog } from '../index';
import { DownLoadAnimate } from 'ucode-components'
const prizeTypeFilter = [
    { text: '微信红包', value: 'RED_PACKET' },
    { text: '实物', value: 'PHYSICAL' },
    { text: '积分', value: 'POINT' },
    { text: '优惠券', value: 'COUPON' },
    { text: '虚拟卡', value: 'CARD' },
    { text: '零钱转账', value: 'CHANGE_OF_MONEY' },
    { text: '核销', value: 'WRITE_OFF' }
];
// const promotionTypeFilter = [
//     { text: '互动抽奖', value: 'INTERACT' },
//     { text: '扫码抽奖', value: 'QR_CODE' },
//     { text: '邀请抽奖', value: 'INVITE' },
//     { text: '签到抽奖', value: 'CHECK_IN' },
// ];

@connect(
    (state) => {
        return {
            // drawRecords: state.queryTools.drawRecords,
            // drawPaging: state.queryTools.drawPaging,
            // perPageCountList: state.queryTools.perPageCountList,
            // drawTotalElements: state.queryTools.drawTotalElements,
            // tagSearch: state.queryTools.tagSearch,
            // dloading: state.queryTools.dloading
            ...state.queryTools,
            ...state.wechat,
        }
    },
    {
        changeDrawSearch,
        changeQuery,
        setNavBar,
        getDrawRecord: getDrawRecord.REQUEST,
        loadRedpacketQueryInfo: loadRedpacketQueryInfo.REQUEST,
        drawRecordExport: drawRecordExport.REQUEST,
    }
)

class DrawInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumns: [ /* 表格 */
                {
                    key: 'uuid',
                    dataIndex: 'uuid',
                    title: '流水号',
                    width: 160,
                    render: (value, record) => <div>{value}</div>
                },
                {
                    key: 'prizeId',
                    dataIndex: 'prizeId',  // poolPrizeId poolPrizeName
                    title: '奖品信息',
                    render: (value, record) => {
                        // INTELLIGENT 自由奖
                        // let text = record.awardType == 'INTELLIGENT' ? record.poolPrizeId ? value : record.awardName : value ? value : '未中奖'; noWinTye?mei:zhong
                        // let text = record.awardType == 'INTELLIGENT' ? (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || `${record.awardName}(暂未推荐奖品)`) : '未中奖' : (record.poolPrizeName || '未中奖');
                        let text = record.awardType == 'INTELLIGENT' ? (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || `${record.awardName}(暂未推荐奖品)`) : '未中奖' : record.notWinType == 'WINNING' ? record.poolPrizeName : '未中奖';
                        return <div>
                            <div
                                className="ellipsis"
                                style={{ width: '100%', color: '#006eff', cursor: 'pointer' }}
                                title={text}
                                onClick={() => this.setState({ prizeDetailDialogShow: true, prizeDetailInfoData: { ...record, text } })}>
                                {/* {value ? value : '未中奖'} */}
                                {text}
                            </div>
                            {text != '未中奖' && value && <div>{record.prizeId}</div>}
                        </div>
                    }
                },
                {
                    key: 'prizeType',
                    dataIndex: 'prizeType',
                    title: '奖品类型',
                    render: (value, record) => <div>{(!record.notWinType || record.notWinType == 'WINNING') ? value && enums.prizeType[value].name : ''}</div>
                },
                // {
                //     key: 'promotionType',
                //     dataIndex: 'promotionType',
                //     title: '奖品类型',
                //     width: 80,
                //     render: (value, record) => <div>{value && promotionTypeFilter.filter(v => v.value === value)[0].text}</div>
                // },
                {
                    key: 'openId',
                    dataIndex: 'openId',
                    title: '用户openId',
                },
                {
                    key: 'appId',
                    dataIndex: 'appId',
                    title: 'appId',
                },
                {
                    key: 'tencentBspLevel',
                    dataIndex: 'tencentBspLevel',
                    title: '天御等级',
                },
                {
                    key: 'loti',
                    dataIndex: 'lotteryTime',
                    title: '中奖时间',
                    render: (value, record) => <div>{formatDate(value)}</div>
                },
                {
                    key: 'ct',
                    dataIndex: 'cashTime',
                    title: '领奖时间',
                    render: (value, record) => <div>{formatDate(value)}</div>
                },
                {
                    key: 'prizeStatus',
                    dataIndex: 'prizeStatus',
                    title: '奖品状态',
                    // render: (value, record) => <div>{value && enums.prizeStatus[value].name}</div>
                    render: (value, record) => {

                        let text = record.awardType == 'INTELLIGENT' ? (!record.notWinType || record.notWinType == 'WINNING') ? (record.poolPrizeName || `${record.awardName}(暂未推荐奖品)`) : '未中奖' : record.notWinType == 'WINNING' ? record.poolPrizeName : '未中奖';
                        if (text == '未中奖') {
                            return <div></div>
                        }

                        const prizeStatusFilter = {
                            'NEW_WINNING': '未领取',
                            'CLAIM_FAIL': '领取失败',
                            'OVERDUE': '已过期',
                            'CLAIM_SUCCESS': `${record.prizeType == 'PHYSICAL' ? '未发货' : record.prizeType == 'WRITE_OFF' ? '待核销' : '领取成功'}`,
                            'REGISTERED': `${record.prizeType == 'PHYSICAL' ? '已发货' : record.prizeType == 'WRITE_OFF' ? '待核销' : '已核销'}`,
                            'UN_INCENTIVE': '待激励',
                            'SCRAP': '已报废',

                            'UNSHIP': '待发货',
                            'SHIP_FAIL': '发放中',
                            'GRANT': '发放中',
                            'SHIPED': '已发货',
                            'REJECT': '驳回',
                            'UNCONFIRMED': '待确认',
                            'ACCEPTED': '已受理'
                        };
                        switch (record.prizeType) {
                            case 'RED_PACKET':
                                if (value === 'CLAIM_SUCCESS' && record.redPacketCashInfo) {
                                    if (record.redPacketCashInfo.wechatRedPacketLogStatus == 'RECEIVED') {
                                        prizeStatusFilter.CLAIM_SUCCESS = '已核销';
                                    } else if (record.redPacketCashInfo.wechatRedPacketLogStatus == 'REFUND') {
                                        prizeStatusFilter.CLAIM_SUCCESS = '已过期';
                                    }
                                }
                                break;
                            case 'PHYSICAL':
                                if (value === 'CLAIM_SUCCESS' && record.expressInfo && record.expressInfo.registerStatus == 'REGISTERED') {
                                    prizeStatusFilter.CLAIM_SUCCESS = '已发货';
                                } else {
                                    prizeStatusFilter.CLAIM_SUCCESS = '未发货';
                                }
                                break;
                            case 'WRITE_OFF':
                                if (value === 'CLAIM_SUCCESS' && record.writeOffRecord) {
                                    if (record.writeOffRecord.writerOffStatus === 'OVER') {
                                        prizeStatusFilter.CLAIM_SUCCESS = '已核销';
                                    } else if (record.writeOffRecord.writerOffStatus === 'FAIL') {
                                        prizeStatusFilter.CLAIM_SUCCESS = '核销失败';
                                    }

                                }

                                break
                        };
                        return <div>{prizeStatusFilter[value]}</div>
                    }
                },
                {
                    key: 'operate',
                    dataIndex: 'province',
                    title: '操作',
                    width: 180,
                    render: (value, record) => {
                        const { redPacketCashInfo = {}, openId, appId } = record;
                        return (
                            <div className="table-operate-btn">
                                {<span onClick={() => this.setState({ expressInfoDialogShow: true, expressInfoData: record.expressInfo })}>提交信息</span>}
                                {(record.prizeType == 'RED_PACKET' || record.prizeType == 'CHANGE_OF_MONEY') && redPacketCashInfo.merchantBillNo && <span onClick={() => {
                                    this.props.loadRedpacketQueryInfo({ prizeType: record.prizeType, appId, merchantBillNo: redPacketCashInfo.merchantBillNo || '', openId, merchantId: redPacketCashInfo.merchantId || '' });
                                    this.setState({ redPacketDialogShow: true })
                                }}>商户订单查询</span>}

                                {record.prizeStatus == 'CLAIM_FAIL' && <span onClick={() => this.setState({ expressInfoDialogShow: true, expressInfoData: record })}>失败原因</span>}
                            </div>
                        )
                    }
                }
            ],
            lotiSort: null,
            ctSort: null,
            // 弹窗信息
            redPacketDialogShow: false,
            redpacketInfoData: {},
            expressInfoDialogShow: false,
            expressInfoData: {},
            prizeDetailDialogShow: false,
            prizeDetailInfoData: {},

            animateShow: 0,

            lotteryHit: true,
        };
    }

    componentDidMount() {
        // 获取抽奖信息
        const { changeQuery, getDrawRecord, tagSearch: { codeUri, senNum }, drawPaging, savePrevquery: { drawQuery }, codeQuerySearch: { query } } = this.props;
        // if (codeUri && senNum && query != drawQuery) {
        //     getDrawRecord({
        //         ...drawPaging, callback: (res) => {
        //             if (/^2\d{2}$/.test(res.status)) {
        //                 changeQuery({ drawQuery: query });
        //             }
        //         }
        //     });
        // }

        if (codeUri && senNum) {
            getDrawRecord({ ...drawPaging, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit });
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUpdate(nextProps) {
    }

    renderFilterHead = (col) => {
        // const list = col.key == 'prizeType' ? prizeTypeFilter : promotionTypeFilter;
        const list = prizeTypeFilter;
        const { drawPaging, getDrawRecord, tagSearch: { codeUri, senNum } } = this.props;
        const value = drawPaging[col.key] ? drawPaging[col.key] : [];
        return (
            <DataTable.FilterableColumnHeader
                list={list}
                value={value}
                onFilter={(value) => {
                    if (codeUri && senNum) {
                        drawPaging[`${col.key}`] = value;
                        getDrawRecord({ ...drawPaging, page: 0, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit })
                    }
                }}
            >{col.title}</DataTable.FilterableColumnHeader>
        )
    }

    renderSortHead = (col) => {
        return (
            <DataTable.SortableColumnHeader
                order={this.state[`${col.key}Sort`]}
                sortKey={col.key}
                onSort={() => { this.handleSort(col.key, this.state[`${col.key}Sort`]) }}
            >{col.title}</DataTable.SortableColumnHeader>
        )
    }

    handleSort = (key, order) => {
        const newOrder = handSortOrder(order);
        const bridgeObg = key == 'loti' ? { lotiSort: newOrder, ctSort: null } : { lotiSort: null, ctSort: newOrder };
        const { drawPaging, getDrawRecord, tagSearch: { codeUri, senNum } } = this.props;
        drawPaging.sort = `lacrs.${key},${newOrder}`;
        if (codeUri && senNum) {
            getDrawRecord({ ...drawPaging, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit });
        }
        this.setState(bridgeObg);
    }

    getColumns() {
        return this.state.tableColumns.map(column => {
            if (['prizeType'].includes(column.key)) {
                return {
                    ...column,
                    headerRender: this.renderFilterHead
                }
            } else if (['lotteryTime', 'cashTime'].includes(column.dataIndex)) {
                return {
                    ...column,
                    headerRender: this.renderSortHead
                }
            } else {
                return column
            }
        })
    }
    //导出
    templateExport = () => {
        const { drawPaging } = this.props;

        this.props.drawRecordExport({
            ...drawPaging,
            hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit,
            callback: () => {
                this.setState({ animateShow: this.state.animateShow + 1 })

            }
        });
        notification.success('导出成功');


    }
    render() {
        const { prizeDetailDialogShow, expressInfoDialogShow, redPacketDialogShow, prizeDetailInfoData, expressInfoData, redpacketInfoData } = this.state;
        const { dloading, tagSearch, drawRecords, drawPaging, perPageCountList, drawTotalElements, getDrawRecord, changeDrawSearch } = this.props;
        const listStyle = drawRecords.length > 0 && !dloading;
        const { codeUri, senNum } = tagSearch;
        const isLottery = codeUri && senNum && (drawPaging.prizeType || drawPaging.isSearch) && drawRecords.length == 0 ? '暂无数据' : '二维码还未抽奖';
        return (
            <div>
                <div className={`${listStyle ? 'table-tr-height50' : 'table-tr-middle'}`} style={{ paddingBottom: !listStyle && 20 }}>
                    <div className="searchbar-box" style={{ overflow: 'visible' }}>
                        {drawRecords.length > 0 && <Button className="tc-15-btn export" onClick={(e) => this.templateExport(true)} style={{ marginRight: 10 }}>导出</Button>}
                        <SearchInput
                            // className="search-input"
                            className="search-input-block"
                            placeholder="输入流水号"
                            onChange={(value) => { changeDrawSearch({ uuid: value }) }}
                            // onClear={() => { changeDrawSearch({ uuid: '' }) }}
                            onSearch={() => {
                                const { codeUri, senNum } = tagSearch;
                                if (codeUri && senNum) {
                                    drawPaging.isSearch = false;
                                    if (drawPaging.uuid) {
                                        drawPaging.isSearch = true;
                                    }
                                    getDrawRecord({ ...drawPaging, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit })
                                }
                            }}
                            value={drawPaging.uuid}
                        />
                        <NewSelect
                            className="table-search-select"
                            style={{ float: 'right', marginRight: 8 }}
                            value={this.state.lotteryHit}
                            list={[{ text: '全部', value: 'all' }, { text: '中奖', value: true }, { text: '未中奖', value: 2 }]}
                            // placeholder="方式"
                            onChange={(value) => {
                                this.setState({ lotteryHit: value })
                            }}
                        />
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <DataTable
                        columns={this.getColumns()}
                        data={drawRecords}
                        rowKey="id"
                        messageRender={({ data, error }) => {
                            if (error != null) { return error.msg; }
                            if (data == null || data.length === 0) { return <span className="text-weak">{isLottery}</span>; }
                        }}
                        loading={dloading}
                    />
                    {listStyle && <div className="page-padding20">
                        <Pager
                            total={drawTotalElements}
                            current={drawPaging.page}
                            perPageCount={drawPaging.size}
                            perPageCountList={perPageCountList}
                            onPageChange={e => { getDrawRecord({ ...drawPaging, page: e, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit }) }}
                            onPerPageChange={e => { getDrawRecord({ ...drawPaging, size: e, page: 0, hit: this.state.lotteryHit == 2 ? false : this.state.lotteryHit }) }} // 每页大小
                        />
                    </div>}
                </div>
                {/* 弹窗 */}
                <PrizeDetailDialog
                    prizeDetailDialogShow={prizeDetailDialogShow}
                    prizeDetailInfoData={prizeDetailInfoData}
                    handleCloseDialog={() => {
                        this.setState({ prizeDetailDialogShow: false });
                    }}
                />

                <ExpressInfoDialog
                    expressInfoDialogShow={expressInfoDialogShow}
                    expressInfoData={expressInfoData}
                    handleCloseDialog={() => {
                        this.setState({ expressInfoDialogShow: false });
                    }}
                />

                <RedpacketInfoDialog
                    redPacketDialogShow={redPacketDialogShow}
                    redpacketInfoData={redpacketInfoData}
                    handleCloseDialog={() => {
                        this.setState({ redPacketDialogShow: false });
                    }}
                />
                <DownLoadAnimate animateIcon='.searchbar-box .export' animateShow={this.state.animateShow}></DownLoadAnimate>
            </div>
        )
    }
}

export default DrawInfo;