import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { changeCodeSearch, changeTabList, resetTagInfo, changeQuery, getCodeInfo, updateEnterprisesSelectdId, removeinfo } = iceStarkData.store.get('commonAction')?.queryTools;
const { loadEnterprisesSelections } = iceStarkData.store.get('commonAction')?.enterprises;
import { CodeInfo, DrawInfo, ScanInfo, AcquisitionInfo, IncentiveInfo } from '../Components/index';
import { SearchBox, SearchInput, TabList, notification } from 'tyb';
import './CodeQuery.scss';
import { NewSelect } from 'ucode-components';
@connect(
    (state) => {
        return {
            codeQuerySearch: state.queryTools.codeQuerySearch,
            tabVal: state.queryTools.tabVal,
            tagType: state.queryTools.tagType,
            tagSearch: state.queryTools.tagSearch,
            enterprisesSelectionsId: state.queryTools.enterprisesSelectionsId,
            openIds: state.users.openIds,
            ...state.enterprises,
        }
    },
    {
        changeCodeSearch,
        changeTabList,
        resetTagInfo,
        changeQuery,
        updateEnterprisesSelectdId,
        removeinfo,
        setNavBar,
        getCodeInfo: getCodeInfo.REQUEST,
        loadEnterprisesSelections: loadEnterprisesSelections.REQUEST,
    }
)

class CodeQuery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabList: [{
                text: '二维码基本信息',
                value: 'code'
            },
            // 迎驾贡注释
            //  {
            //     text: '抽奖信息',
            //     value: 'draw'
            // }, {
            //     text: '扫码信息',
            //     value: 'scan'
            // },
            // {
            //     text: '数据采集信息',
            //     value: 'acquisition'
            // },
            // {
            //     text: '激励信息',
            //     value: 'incentive'
            // }
            ],
            tagTypeList: [{
                text: '码链接',
                value: '1'
              
            },
            {
                // 迎驾贡注释
                // text: '码序号/物流码',
                text: '码序号',
                value: '2'
            },
            // {
            //     text: 'PIN码',
            //     value: '4'
            // },
            ]
        };
    }

    componentDidMount() {
        const { openIds, loadEnterprisesSelections, location } = this.props;
       
        if (openIds.userType == 'SYSTEM') {
            loadEnterprisesSelections();
            this.state.tagTypeList.push({ text: '系统序号', value: '3' });
        }
        const parse = queryString.parse(this.props.location.search);
        if(parse.query && parse.type){
            this.props.changeCodeSearch({...this.props.codeQuerySearch,  query: parse.query, type: parse.type});
            this.props.getCodeInfo({query: parse.query, type: parse.type});
        }
    }
    componentWillUnmount() {
        this.props.changeCodeSearch({ type: 1, query: '' });
        this.props.updateEnterprisesSelectdId()
        this.props.removeinfo()
    }

    render() {
        const { tabList, tagTypeList } = this.state;
        const {
            openIds,
            codeQuerySearch,
            tabVal,
            tagSearch,
            changeTabList,
            changeCodeSearch,
            resetTagInfo,
            changeQuery,
            getCodeInfo,
            enterprisesSelectionsId,
            enterprisesSelectionsList = [],
            updateEnterprisesSelectdId } = this.props;
        return (
            <div className={`table-padding-bottom`}>
                <div className="codeinfo-searchbar-box searchbar-box" style={{ textAlign: 'right', marginTop: 10 }}>
                    {openIds.userType == 'SYSTEM' &&
                        <div style={{ display: 'inline-block', marginRight: 8, verticalAlign: 'top', marginTop: 10 }}>
                            <NewSelect
                                className="table-search-select"
                                value={codeQuerySearch.type == 3 ? '' : enterprisesSelectionsId}
                                list={enterprisesSelectionsList}
                                placeholder="企业"
                                disabled={codeQuerySearch.type == 3}
                                onChange={updateEnterprisesSelectdId}
                                style={{ width: 110 }}
                            />
                        </div>}
                    <div style={{ display: 'inline-block', marginRight: 8, verticalAlign: 'top', marginTop: 10 }}>
                        <NewSelect
                            className="table-search-select"
                            value={codeQuerySearch.type}
                            list={tagTypeList}
                            placeholder="类型"
                            style={{ width: 110 }}
                            onChange={(value) => {
                                if (codeQuerySearch.type != 3 && value == 3) {
                                    updateEnterprisesSelectdId();
                                }
                                if (codeQuerySearch.type == 3 && value != 3) {
                                    updateEnterprisesSelectdId();
                                }
                                changeCodeSearch({ type: value });
                            }}
                        /></div>
                    <SearchInput
                        className="table-search-select table-search-select100"
                        style={{ width: 450, display: 'inline-block', float: 'none', verticalAlign: 'top', marginRight: 0, marginTop: 10 }}
                        placeholder='请输入'
                        onChange={(value) => {
                            changeCodeSearch({ query: value })
                        }}
                        // onClear={() => { changeCodeSearch({ query: '' }) }}
                        onSearch={() => {
                            const { type = '', query } = codeQuerySearch;
                            const reset = function (flag) { // 先清除旧数据
                                if (!flag) {
                                    tagSearch.codeUri = '';
                                    tagSearch.senNum = '';
                                }
                                resetTagInfo();
                                // changeQuery({ drawQuery: '', scanQuery: '' });
                                changeTabList('code');
                            }
                            if (openIds.userType == 'SYSTEM' && !enterprisesSelectionsId && type != 3) {
                                notification.error('请选择企业');
                            } else if (!type) {
                                notification.error('请选择查询类型');
                            } else if (!query) {
                                notification.error('请输入查询内容');
                            } else if (type == 2 && !(/^\s*\d+\s*$/.test(query))) {
                                notification.error('类型和查询信息不匹配');
                                reset(false);
                                // } else if (type == 1 && query.search(/http/i) != 0) { // query.search(/http/i) 返回第一个匹配所在位置
                                //     notification.error('类型和查询信息不匹配');
                                //     reset(false);
                            } else if (type == 1 && (/^\s*\d+\s*$/.test(query))) {
                                notification.error('类型和查询信息不匹配');
                                reset(false);
                            } else if (type == 3 && !(/^\s*\d+\s*$/.test(query))) {
                                notification.error('类型和查询信息不匹配');
                                reset(false);
                            }
                            // else if (type == 4 ) {
                            //     notification.error('类型和查询信息不匹配');
                            //     reset(false);
                            // }
                            else {
                                reset(true);
                                changeQuery({ codeQuery: query, codeQuerySearch })

                                if (type == 3) {
                                    updateEnterprisesSelectdId();
                                }

                                getCodeInfo({ ...codeQuerySearch, query: codeQuerySearch.query.trim() });
                            }
                            // resetTagInfo();
                            // getCodeInfo({ eseId: '124', query: 'HTTP://UT.AAX6.CN/A/5BJ+E568DVY93$FQ178' });
                        }}
                        value={codeQuerySearch.query}
                    />
                    <div style={{ clear: 'both' }}></div>
                </div>
                <div className="codeinfo-box">
                    <div className="tabbar">
                        <TabList
                            value={tabVal}
                            list={tabList}
                            onChange={(val) => { changeTabList(val) }}
                        />
                    </div>
                    <div style={{ padding: '0 20px' }}>
                        {tabVal === "code" && <CodeInfo />}
                        {tabVal === "draw" && <DrawInfo />}
                        {tabVal === "scan" && <ScanInfo />}
                        {tabVal === "acquisition" && <AcquisitionInfo />}
                        {tabVal === "incentive" && <IncentiveInfo />}
                    </div>
                </div>
            </div>
        )
    }
}

export default CodeQuery;