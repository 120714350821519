import React from 'react';
import { connect } from 'react-redux';
import { DataTable} from 'tyb';
const { loadpointRecordDetailList } = iceStarkData.store.get('commonAction')?.queryTools;
import { pointTableHead } from '../../UserQuery/UserQueryTableHead';
import '../../UserQuery/UserQuery.scss';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;

@connect (
  state => {
    return {
      ...state.queryTools,
    };
  },
  {
    setNavBar,
    loadpointRecordDetailList: loadpointRecordDetailList.REQUEST,
  }
)
class PointRecord extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
    };
  }

  componentDidMount () {
  }

  formatTableHead = table => {
    return pointTableHead.map (column => {
      if (column.key === 'action') {
        return {
          ...column,
          render: (value, record) => {
            return (
              <div
                className="cursor-handle"
                onClick={() => {
                  const { loadpointRecordDetailList } = this.props;
                  loadpointRecordDetailList ({ pointType: record.pointType, page: 0, size: 20, });
                  this.props.setNavBar ( record.pointName);
                  this.props.history.push ('querytoolsqt/pointDetail');
                }}
                style={{color: '#006EFF'}}
              >
                查看
              </div>
            );
          },
        };
      } else {
        return column;
      }
    });
  };

  render () {
    const {currentOpenId = '', pointRecordList = [], pointRecordLoad} = this.props;
    return (
      <div>
        <div className="records-list-head">
          <span className="table-title">openID: {currentOpenId}</span>
        </div>
        <div className="lottery-record-box">
          <div className="user-tablelist">
            <DataTable
              columns={this.formatTableHead ('pointTableHead')}
              data={pointRecordList}
              rowKey="id"
              loading={pointRecordLoad}
              style={{width: '100%', maxWidth: '100%', border: '1px solid #E2E2E2', borderBottom:'none'}}
              messageRender={({data, error}) => {
                if (error != null) {
                  return error.msg;
                }
                if (data == null || data.length === 0) {
                  return <span className="text-weak">暂无数据</span>;
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PointRecord;
