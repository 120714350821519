import React from 'react';
import { Dialog, Button } from 'tyb';
import './expressInfoDialog.scss';

class ExpressInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }

  componentDidUpdate(prevProps) { }
  handleFooterRender = () => {
    const { handleCloseDialog } = this.props;
    return (
      <div className="tc-15-rich-dialog-ft-btn-wrap">
        <Button className="weak" onClick={() => { handleCloseDialog(); }} > 关闭 </Button>
      </div>
    );
  };

  render() {
    const { expressInfoDialogShow, handleCloseDialog, expressInfoData = {}, } = this.props;
    const { consignee, phoneNumber, idCardNo, provinceName, cityName, districtName, address, email, qqId, wechatId, expressNo, companyName, prizeStatus, shippingAddress = {} } = expressInfoData;
    return (
      <Dialog
        className="expressinfo-dialog"
        style={{ width: '365px', minWidth: '365px' }}
        title={prizeStatus == 'CLAIM_FAIL' ? '失败原因' : "奖品提交信息"}
        visible={expressInfoDialogShow}
        footerRender={this.handleFooterRender}
        onCancel={handleCloseDialog}
      >
        {prizeStatus == 'CLAIM_FAIL' ?
          <div className="expressinfo-dialog-content">
            <ul>
              <li><span>失败code</span><span>{expressInfoData.cashErrorCode}</span></li>
              <li><span>失败原因</span><span>{expressInfoData.cashErrorCodeDes}</span></li>
            </ul>
          </div>
          : <div className="expressinfo-dialog-content">
            <ul>
              <li><span>姓名</span><span>{consignee}</span></li>
              <li><span>手机</span><span>{phoneNumber}</span></li>
              <li><span>身份证</span><span>{idCardNo}</span></li>
              <li><span>地址</span> <span> {provinceName} {cityName} {districtName} {address} </span> </li>
              <li><span>邮箱</span><span>{email}</span></li>
              <li><span>QQ号</span><span>{qqId}</span></li>
              <li><span>微信号</span><span>{wechatId}</span></li>
              <li><span>快递单号</span><span>{expressNo}</span></li>
              <li><span>快递公司</span><span>{companyName}</span></li>
              {(shippingAddress.userName || shippingAddress.telNumber || shippingAddress.postalCode || shippingAddress.provinceName || shippingAddress.cityName || shippingAddress.countyName) &&
                <React.Fragment>
                  <li><span>收货信息</span><span style={{ color: '#888', width: 60 }}>收货人</span><span style={{ width: 'calc(100% - 148px)' }}>{shippingAddress.userName}</span></li>
                  <li><span style={{ marginLeft: 88, width: 60 }}>收货电话</span><span style={{ width: 'calc(100% - 148px)' }}>{shippingAddress.telNumber}</span></li>
                  <li><span style={{ marginLeft: 88, width: 60 }}>收货邮编</span><span style={{ width: 'calc(100% - 148px)' }}>{shippingAddress.postalCode}</span></li>
                  <li><span style={{ marginLeft: 88, width: 60 }}>收货地址</span><span style={{ width: 'calc(100% - 148px)' }}>{shippingAddress.provinceName} {shippingAddress.cityName} {shippingAddress.countyName} {shippingAddress.detailInfo}</span></li>
                </React.Fragment>}
            </ul>


          </div>}
      </Dialog>
    );
  }
}

export default ExpressInfo;
