import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Button } from 'tyb';
import './RedpacketInfoDialog.scss';

const REDPACK_STATUS = {
  SENDING: '发放中',
  SENT: '已发放待领取',
  FAILED: '发放失败',
  RECEIVED: '已领取',
  RFUND_ING: '退款中',
  REFUND: '已退款',
  SUCCESS: '转账成功',
  FAILED: '转账失败',
  PROCESSING: '处理中',
}

@connect(
  state => {
    return {
      ...state.queryTools,
      ...state.wechat,
    }
  },
  {
    // loadQueryUserMetadata: loadQueryUserMetadata.REQUEST,
  }
)
class RedpacketInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }

  handleFooterRender = () => {
    const { handleCloseDialog } = this.props;
    return (
      <div className="tc-15-rich-dialog-ft-btn-wrap">
        <Button className="weak" onClick={() => { handleCloseDialog() }}>关闭</Button>
      </div>
    );
  }

  render() {
    const { redPacketDialogShow, handleCloseDialog, redpacketQueryInfo: redpacketInfoData = {} } = this.props;

    return (
      <Dialog
        className="redpacket-dialog"
        style={{ width: '465px', minWidth: '365px' }}
        title="商户订单信息"
        visible={redPacketDialogShow}
        footerRender={this.handleFooterRender}
        onCancel={handleCloseDialog}
      >
        <div className="redpacket-dialog-content">
          <ul>
            <li><span>商户订单号</span><span>{redpacketInfoData.merchantBillNo || redpacketInfoData.partnerTradeNo}</span></li>
            <li><span>面值</span><span>{(redpacketInfoData.totalAmount || redpacketInfoData.paymentAmount) ? (redpacketInfoData.totalAmount || redpacketInfoData.paymentAmount) / 100 : ''}元</span></li>
            <li><span>请求时间</span><span>{redpacketInfoData.sendTime || redpacketInfoData.transferTime}</span></li>
            {redpacketInfoData.paymentTime && <li><span>转账成功时间</span><span>{redpacketInfoData.paymentTime}</span></li>}
            <li><span>订单状态</span><span>{redpacketInfoData.status ? REDPACK_STATUS[redpacketInfoData.status] : ''}</span></li>
            <li><span>失败信息</span><span>{(redpacketInfoData.errCodeDes == 'OK' || redpacketInfoData.errCodeDes == '成功') ? redpacketInfoData.reason || '' : (redpacketInfoData.errCodeDes || redpacketInfoData.errCodeDes)}</span></li>
            {/* v3的弹窗会多“商家批次单号”字段 */}
            {redpacketInfoData.transferBatchId && <li><span>商家批次单号</span><span>{redpacketInfoData.transferBatchId}</span></li>}
          </ul>
        </div>
      </Dialog>
    )
  }
}
export default RedpacketInfo;